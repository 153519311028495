@font-face {
    font-family: "Geomanist";
    src: url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Regular.woff2")
            format("woff2"),
        url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Regular.woff")
            format("woff"),
        url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Regular.otf")
            format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Geomanist";
    src: url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Medium.woff2")
            format("woff2"),
        url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Medium.woff")
            format("woff"),
        url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Medium.otf")
            format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Geomanist";
    src: url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Bold.woff2")
            format("woff2"),
        url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Bold.woff")
            format("woff"),
        url("https://d2btcxja5g5zgs.cloudfront.net/assets/community-call/fonts/geomanist/Geomanist-Bold.otf")
            format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

body.dark {
    background-color: #020608;
}